/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-use-before-define */
/* eslint-disable no-console */
/* eslint-disable func-names */
// @flow

import "./site.scss";

import EventBus from "./EventBus";

document.addEventListener("DOMContentLoaded", () => {
  setupEventBusAndDnD();
  TextFieldManager.init();

  const bodyEl = document.getElementById('pageBody');
  if (bodyEl) {
    _onLoad();
  }
});

const setupEventBusAndDnD = () => {
  const vdxEventBus = new EventBus("vdx-event-bus");
  window.vdxEventBus = vdxEventBus;
  vdxEventBus.on("dnd-in-updated", ({ detail }) => {
    // trigger save/cancel buttons to appear
    // caw_onChangeListener(document.Frm);
  });
};

window._onLoad = function () {
  if (window.onLoad) {
    window.onLoad();
  }

  // see <appbuilder:page-loading/>
  const pageLoader = document.getElementById("page-loading");
  if (pageLoader) {
    pageLoader.classList.add("fade-out");
  }
};

window.parseQueryString = function (str) {
  // eslint-disable-next-line prefer-const
  let objURL = {};

  str.replace(
    new RegExp("([^?=&]+)(=([^&]*))?", "g"),
    function ($0, $1, $2, $3) {
      objURL[$1] = $3;
    }
  );
  return objURL;
};

let TextFieldManager = (function () {
  function initTextFields() {
    let textFields = document.querySelectorAll(".md-text-field");
    for (let i = 0; i < textFields.length; i++) {
      initTextField(textFields[i]);
    }
  }

  function initTextField(textField) {
    let input = textField.querySelector(
      "input[type=text].md-text-field__input"
    );
    let label = textField.querySelector("label.md-floating-label");

    if (input && label) {
      if (input.value) {
        label.classList.add("md-floating-label--float-above");
      }

      input.addEventListener("change", (evt: Event) => {
        if (!input.value) {
          textField.classList.remove("md-text-field--invalid");
        } else if (input.matches(":invalid")) {
          textField.classList.add("md-text-field--invalid");
        } else {
          textField.classList.remove("md-text-field--invalid");
        }
      });

      input.addEventListener("focus", (evt: Event) => {
        label.classList.add("md-floating-label--float-above");
        textField.classList.add("md-text-field--focused");
      });

      input.addEventListener("blur", (evt: Event) => {
        if (input.value) {
          label.classList.add("md-floating-label--float-above");
        } else {
          label.classList.remove("md-floating-label--float-above");
        }

        textField.classList.remove("md-text-field--focused");
      });

      input.addEventListener("invalid", (evt: Event) => {
        textField.classList.add("md-text-field--invalid");
      });

      input.addEventListener("valid", (evt: Event) => {
        textField.classList.remove("md-text-field--invalid");
      });
    }
  }

  return {
    init() {
      initTextFields();
    },
  };
})();
